/* eslint-disable key-spacing */
/* eslint-disable no-useless-escape */
/* eslint-disable spaced-comment */
/* eslint-disable new-cap */
/* eslint-disable standard/computed-property-even-spacing */
/* eslint-disable no-unused-expressions */
import { mapActions, mapGetters } from 'vuex'
import { showVueToast, formatDate, nameToPath } from '@/utils'
import dayjs from 'dayjs'

const BiodataCard = () => import(/* webpackChunkName: "change-password-modal" */ '@/components/student/BiodataCard')
const DescriptionCard = () => import(/* webpackChunkName: "change-password-modal" */ '@/components/student/DescriptionCard')
const MyClassCard = () => import(/* webpackChunkName: "change-password-modal" */ '@/components/student/MyClassCard')
const ChangePasswordModal = () => import(/* webpackChunkName: "change-password-modal" */ '@/components/student/ChangePasswordModal')
const LeaveClassModal = () => import(/* webpackChunkName: "upload-tugas-modal" */ '@/components/student/LeaveClassModal')

export default {
  name: 'profile-student',
  filters: {
    formatDate
  },
  components: {
    BiodataCard,
    DescriptionCard,
    MyClassCard,
    ChangePasswordModal,
    LeaveClassModal,
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    LinkButton: () => import(/* webpackChunkName: "button" */ '@/components/button/LinkButton'),
    EditForm: () => import(/* webpackChunkName: "form" */ '@/views/Student/EditForm'),
    Settings: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Settings'),
    Calendar: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Date'),
    Enter: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Enter'),
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron'),
    PrakerjaClass: () => import(/* webpackChunkName: "PrakerjaClass" */ '@/components/student/PrakerjaClass'),
    ProductCard: () => import(/* webpackChunkName: "productcard" */ '@/components/card/ProductCard')
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  data() {
    return {
      urlImage: '',
      name: '',
      joinDate: '',
      email: '',
      phoneNumber: '',
      alertLable: '',
      className: '',
      classId: '',
      visibleChangePassModal: false,
      visibleLeaveClassModal: false,
      visibleEditModal: false,
      resetPassEmail: null,
      description: '',
      classData: [],
      bootcampClassData: [],
      administrationClassData: [],
      myClass: [],
      prakerjaClass: [],
      administrationClass: [],
      forClassCheck: [],
      dateNow: dayjs().format('DD MMMM YYYY'),
      cordinateY: 70,
      perPage: 100,
      page: 0,
      totalClass: 0,
      tabs: [
        {
          title: 'My Class',
          icon: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Class')
        },
        {
          title: 'Bootcamp Class',
          icon: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Bootcamp')
        },
        {
          title: 'Administration',
          icon: () => import(/* webpackChunkName: "icon" */ '@/components/icons/FolderOpen')
        }
      ],
      selectedTab: 'My Class',
      isLoadData: false
    }
  },
  created() {
    this.getStudents()
  },
  computed: {
    ...mapGetters('common', ['isMobile', 'loading']),
    ...mapGetters('prakerja', ['userProfile']),
    emailStudent() {
      return this.email
    },
    studentId() {
      return this.userProfile?.student?.id
    },
    isTextAreaActive() {
      if (this.description.length === 0 && !this.isEditDesc) {
        return true
      }
    }
  },
  methods: {
    ...mapActions('student', ['getAdministration', 'getScheduleOnward', 'createAttendance', 'uploadImage', 'updateStudent', 'updateEmailStudent', 'getMyClass', 'getStudentDetail']),
    ...mapActions('common', ['showLoading', 'hideLoading', 'setPhotoChanged']),
    ...mapActions('prakerja', ['getUserProfile']),
    ...mapActions('prakerja', { getPrakerjaClass: 'getMyClass' }),
    generatePathFriendly(text) {
      return nameToPath(text)
    },
    clickTab(tab) {
      this.isLoadData = true
      this.selectedTab = tab
      if (tab === 'Bootcamp Class') return this.getBootcampClass()
      if (tab === 'Administration') return this.getAdministrationStudent()
      this.getStudentClass()
    },
    async getStudents() {
      this.showLoading()
      this.isLoadData = true
      await this.getUserProfile().then((response) => {
        localStorage.setItem('student_id', response?.student?.id)
        this.name = response.name
        this.email = response.email
        this.phoneNumber = response?.hp || ''
        this.description = response?.student?.description || ''
        const image = response?.student?.photo || ''
        this.urlImage = image
        localStorage.setItem('photo', image)
        this.setPhotoChanged({ data: image })
        this.joinDate = response?.student?.createdDate && dayjs(response?.student?.createdDate).format('DD-MM-YYYY')
      })
      this.getStudentClass()
      this.getBootcampClass('', true)
      this.hideLoading()
    },
    getAdministrationStudent() {
      this.showLoading()
      this.administrationClassData = []
      this.getAdministration().then(
        (response) => {
          if (response.data.code === 200) {
            this.administrationClassData = response.data.data
            this.isLoadData = false
            this.hideLoading()
          }
        },
        () => { }
      )
    },
    getStudentClass() {
      this.showLoading()
      this.classData = []
      this.getPrakerjaClass().then((response) => {
        const filteredArray = response.filter((value) => !this.forClassCheck.includes(value.lms_class_id))
        this.classData = filteredArray
        this.isLoadData = false
        this.hideLoading()
      })
    },
    getBootcampClass(searchTerm = '', isCheckData) {
      this.showLoading()
      this.getMyClass({ params: { page: this.page, size: this.perPage, name: searchTerm } })
        .then((response) => {
          if (isCheckData) {
            response.data.forEach((element) => {
              this.forClassCheck.push(element.id)
            })
            return
          }
          this.bootcampClassData = []
          response.data.forEach((element) => {
            this.forClassCheck.push(element.id)
          })
          this.totalClass = response.totalRows
          if (response.data.length === 0) {
            this.isLoadData = false
            this.hideLoading()
          } else {
            response.data.map((classes) => {
              this.getScheduleOnward({ classId: classes.id }).then(
                (response) => {
                  if (response.length > 0) {
                    this.bootcampClassData.push({ ...classes, isScheduleAvailable: true })
                  } else {
                    this.bootcampClassData.push({ ...classes, isScheduleAvailable: false })
                  }
                  this.isLoadData = false
                  this.hideLoading()
                },
                () => { }
              )
            })
          }
        })
        .catch(() => { })
    },
    handlePageChange(page) {
      this.page = page - 1
      this.getBootcampClass()
    },
    handleSearch(searchTerm) {
      this.getBootcampClass(searchTerm)
    },
    selectImage(event) {
      if (event.target.files[0]) {
        const selectedFiles = event.target.files[0]
        const nameFile = event.target.files[0].name
        const fileImage = new FormData()
        fileImage.append('file', selectedFiles)
        fileImage.append('name', nameFile)

        this.uploadImage({ data: fileImage }).then(
          (response) => {
            this.urlImage = response.url
            localStorage.setItem('photo', response.url)
            this.updateProfile('photo')
            this.setPhotoChanged({ data: response.url })
          },
          () => {
            this.hideLoading()
          }
        )
      }
    },
    saveProfile(data) {
      this.description = data.description
      this.phoneNumber = data.phoneNumber
      this.name = data.name
      this.urlImage = data.photo
      this.updateProfile('profile')
    },
    updateProfile(item) {
      this.showLoading()
      const payloads = {
        description: this.description,
        phoneNumber: this.phoneNumber,
        photo: this.urlImage,
        name: this.name
      }
      this.updateStudent({ studentId: this.studentId, payloads }).then(
        () => {
          let alert = ''
          switch (item) {
            case 'description':
              alert = 'Description'
              break
            case 'phone':
              alert = 'Phone Number'
              break
            case 'profile':
              alert = 'Profile'
              this.toggleEditModal()
              break
            default:
              alert = 'Profile Photo'
          }
          this.hideLoading()
          showVueToast(`${alert} is updated successfully!`, 'success', 3000)
        },
        () => {
          this.hideLoading()
        }
      )
    },
    toggleEditModal() {
      this.visibleEditModal = !this.visibleEditModal
    },
    handleInputEmail(value) {
      this.email = value
    },
    handleInputPhone(value) {
      this.phoneNumber = value
    },
    handleInputDescription(value) {
      this.description = value
    },
    saveItem(item) {
      if (item === 'email') {
        const payloads = {
          email: this.email
        }
        this.updateEmailStudent({ studentId: this.studentId, payloads }).then(
          () => {
            showVueToast('Email berhasil diupdate!', 'success', 2000)
          },
          () => {
            showVueToast('Update email gagal, terjadi kesalahan pada sistem!', 'error', 3000)
            this.hideLoading()
          }
        )

        return
      }
      this.updateProfile(item)
    },

    emailValidation() {
      const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      if (this.email === '') {
        showVueToast('Email tidak boleh kosong!', 'error', 3000)
      } else if (!regex.test(this.editedEmail)) {
        showVueToast('Format email yang anda masukkan salah!!', 'error', 3000)
      } else {
        return true
      }
    },
    toggleChangePassModal() {
      this.visibleChangePassModal = !this.visibleChangePassModal
    },
    goDashboard(id, classType) {
      if (!classType) return this.$router.push({ path: `/administration/${id}` })
      if (classType === 'PRAKERJA') {
        this.$router.push({ path: `/dashboard-prakerja/${id}` })
        return
      }
      this.$router.push({ path: `/dashboard/${id}` })
    },

    doLeaveClass(lmsScheduleId, alasan, attend) {
      const payloads = {
        alasan,
        attend,
        lmsScheduleId
      }
      this.createAttendance({ payloads }).then(
        () => {
          showVueToast('Izin absensi berhasil!', 'success', 3000)
        },
        () => {
          showVueToast('Anda sudah melakukan izin!', 'error', 4000)
        }
      )
      this.toggleLeaveClassModal()
    },

    toggleLeaveClassModal(lmsClassId, lmsClassName) {
      this.classId = lmsClassId
      this.className = lmsClassName
      this.visibleLeaveClassModal = !this.visibleLeaveClassModal
    },
    // isActive(deadline) {
    //   const add7Day = dayjsz(deadline).add(7, 'days')
    //   if (dayjsz(this.dateOnly).isAfter(add7Day)) {
    //     return true
    //   }
    // },

    setNewLine(line) {
      switch (line) {
        case 1:
          return this.cordinateY + 5
        case 2:
          return this.cordinateY + 15
        case 3:
          return this.cordinateY + 25
      }
    }
  }
}
